// Classes
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader from '@/Components/Global/BasicHeader/template.vue'
import Spinner     from '@/Components/Global/Spinner/template.vue'
import ModuleBox   from '@/Components/Modules/1/ModuleBox/template.vue'

// Components (Refs)
import { BasicHeaderRef } from '@/Components/Global/BasicHeader/component'

// Constants
import { AppModules }  from '@/Constants/AppModules'
import { AppValues }   from '@/Constants/AppValues'
import { Breakpoints } from '@/Constants/Breakpoints'
import { VueRouter }   from '@/Constants/VueRouter'
import { Vuex }        from '@/Constants/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Export
export default VueMixins(MixinBase, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Views.Modules.INTERNAL_CPANEL.NAME,

	components: {
		BasicHeader,
		ModuleBox,
		Spinner
	},

	props: {
		unauthorizedRedirect: Boolean
	},

	data: function() {
		return {
			permissions: {
				[AppValues.ObjectIds.Modules.ADMINISTRATION.toUpperCase()]: null as any,
				[AppValues.ObjectIds.Modules.CHECKLISTS.toUpperCase()]: null as any,
				[AppValues.ObjectIds.Modules.SERVICES.toUpperCase()]: null as any,
				[AppValues.ObjectIds.Modules.SETUP_CHECKLIST.toUpperCase()]: null as any,
				[AppValues.ObjectIds.Modules.STATISTICS.toUpperCase()]: null as any
			}
		}
	},

	created: function() {
		// Validación para la Redirección no Autorizada.
		const { unauthorizedRedirect } = this.$props
		if (unauthorizedRedirect) this.showToast('Acceso No Autorizado', 'No tienes permisos para acceder al Sistema.', 'danger')

		// Obtención de Permisos y Modulos (Almacenados en Store).
		Store.dispatch('fetchPermissions')
		Store.dispatch('fetchSystems')
	},

	mounted: function() {
		if (this.$route.params.successLogin === 'true') {
			const { name, pLastName } = Store.getters.getStoredUser
			this.showToast(`Bienvenid@, ${ name } ${ pLastName }`, 'Has iniciado sesión correctamente!', 'success')
		}
		this._initPermissions()
		Store.commit('updateViewsViewMode', VueRouter.ViewModes.INTERNAL)
	},

	computed: {
		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},
		
		_getSystems: function() {
			// Los Sistemas se almacenarán todos juntos en una misma propiedad.
			const storedSystems: Array<any> = Store.getters.getStoredSystems

			// El filtro será aplicado directamente en la vista, validando que exista al menos un sistema en el array.
			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(storedSystems)) {
				// Obtener los Sistemas correspondientes a la constante de los Clientes.
				const systemsIds = Object.keys(AppModules.Global.InternalModulesList)
				
				// Array que debe contener solamente los Sistemas de los Ids extraidos de la constante.
				return PrimitiveTools.Arrays.getFromStackByProperty(storedSystems, systemsIds, '_idSystem').sort((a: any, b: any) => {
					const $a = AppModules.Global.InternalModulesList[a._idSystem]?._params.index
					const $b = AppModules.Global.InternalModulesList[b._idSystem]?._params.index
					return ($a > $b)  ? 1 : -1
				})
			}
		}
	},

	methods: {
		_getSystemParam: function(system: System, property: string) {
			return AppModules.Global.InternalModulesList[system._idSystem]?._params[property]
		},
		
		_getSystemProperty: function(system: System, property: string) {
			return (AppModules.Global.InternalModulesList[system._idSystem] as any)?.[property]
		},

		_hasPermissionToSystem: function(system: System) {
			return this.permissions[system._idSystem.toUpperCase()]
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._setPermission(AppValues.ObjectIds.Modules.ADMINISTRATION.toUpperCase(),  userPermissions.ACCESS_MODULE_20?.privileges.read)
			this._setPermission(AppValues.ObjectIds.Modules.CHECKLISTS.toUpperCase(),      userPermissions.ACCESS_MODULE_40?.privileges.read)
			this._setPermission(AppValues.ObjectIds.Modules.SERVICES.toUpperCase(),        userPermissions.ACCESS_MODULE_30?.privileges.read)
			this._setPermission(AppValues.ObjectIds.Modules.SETUP_CHECKLIST.toUpperCase(), userPermissions.ACCESS_MODULE_41?.privileges.read)
			this._setPermission(AppValues.ObjectIds.Modules.STATISTICS.toUpperCase(),      true)
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Cambios que afectan el aspecto visual de los Componentes (según Breakpoint).
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
		},

		_setPermission: function(key: string, value: boolean) {
			(this.permissions as any)[key] = value
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions')
	}
})

interface System {
	_idSystem: ObjectId
}